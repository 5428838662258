// config.js

const bizIcon = 'https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/S240T0B8RizQTdMSQeqb/media/67435af685656f57d6ea02c6.png';
const myBizIcon = 'https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg';
const imageOne = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/19MS4yR9l69jyEI8PaA0/media/138bb885-8386-4a10-9f4b-92e872bd061b.webp";
const imageTwo = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/19MS4yR9l69jyEI8PaA0/media/6740c4b7e7467676111b2eb1.png";
const imageThree = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/19MS4yR9l69jyEI8PaA0/media/1cddb4d7-6499-4b61-ad74-7afdbe178aaf.jpeg";
const imageFour = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/19MS4yR9l69jyEI8PaA0/media/6740c4b77776d587518f8f6f.png";
const imageFive = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/19MS4yR9l69jyEI8PaA0/media/6745b716a0500f7c03e9e351.jpeg";

const redirectLink = "https://ceasardetailing.com/";
export const siteConfig = {
  title: "Ceasar Detail | Detailing Service",
  metaDescription: "Ceasar Detail | Detailing Service",
  theme: {
    primaryColor: "blue-600",
    secondaryColor: "gray-900",
    accentColor: "text-blue-600"
  },
  logo: {
    src: bizIcon,
    alt: "Ceasar Detail | Detailing Service",
  },
  navigation: [
    { name: "Services", sectionId: "services" },
    { name: "About", sectionId: "about" },
    { name: "Location", sectionId: "location" },
  ],
  businessDetails: {
    name: "Ceasar Detail | Detailing Service",
    headline: "Expert Car Detailing in Knoxville, TN",
    address: "Knoxville, Tennessee, United States",
    description: `
        Ceaser Detail is Knoxville's premier detailing service, specializing in restoring and protecting vehicles with expert care. From interior deep cleaning to exterior polishing, we ensure your car looks its absolute best. Dedicated to quality, precision, and customer satisfaction, Ceaser Detail brings out the shine in every ride.`,
  },  
  images: {
    business: {
      src: imageOne,
      alt: "Expert Car Detailing in Knoxville, TN",
      width: 2432,
      height: 1442
    }
  },
  features: [
    {
        description: 
          "Paint Correction Expertise: Restore your vehicle's original shine and gloss by eliminating blemishes, swirl marks, light scratches, chemical stains, and etching from bird droppings. Ceaser Detail ensures your car's paint looks flawless and vibrant.",
      },
    {
        description: 
          "Attention to Detail: Using advanced techniques and premium products, we meticulously enhance your vehicle's appearance, tackling even the most challenging imperfections for a showroom-worthy finish.",
      },
    {
        description: 
          "Why Choose Us: Paint correction is not just about aesthetics—it’s about preserving your vehicle's value. Our services are designed to protect and maintain the integrity of your car's exterior while delivering unmatched gloss and shine.",
      },
  ],
  services: {
    sectionTitle: "Services",
    description: 
    "Ceaser Detail | Detailing Service is proud to serve Knoxville, Tennessee, and the surrounding areas with top-tier detailing services. From restoring your vehicle's exterior to enhancing its interior comfort, our comprehensive offerings are tailored to meet your car care needs. Whether you're seeking protection, restoration, or convenience, we deliver exceptional results every time.",
    callouts: [
        {
          name: 'Ceramic Coatings',
          description: 
          "Protect your vehicle's paint with advanced ceramic coatings that provide long-lasting shine, hydrophobic properties, and resistance to scratches, dirt, and UV damage. Keep your car looking like new for years to come.",
          imageSrc: imageTwo,
          imageAlt: 'Vehicle undergoing ceramic coating application in Knoxville, TN',
        },
        {
          name: 'Paint Correction',
          description: 
          "Restore your vehicle's original gloss and shine with meticulous paint correction services. We remove swirl marks, scratches, oxidation, and other imperfections to bring your car's paint back to life.",
          imageSrc: imageThree,
          imageAlt: 'Car with restored and polished paint in Knoxville, TN',
        },
        {
          name: 'Mobile Detailing',
          description: 
          "Enjoy the convenience of professional mobile detailing services that come to you. From thorough interior cleaning to exterior enhancements, we ensure your vehicle looks its best wherever you are.",
          imageSrc: imageFour,
          imageAlt: 'Mobile detailing service in progress in Knoxville, TN',
        },
    ]
  },  
  about: {
    sectionTitle: "About",
    description:   
      "Welcome to Ceaser Detail | Detailing Service! Proudly serving Knoxville, Tennessee, and surrounding areas, we specialize in professional car detailing services designed to enhance and protect your vehicle. With years of experience and a passion for excellence, our team delivers meticulous attention to detail in every service we provide. From paint correction to ceramic coatings and mobile detailing, we ensure your car looks and feels like new. Discover the difference of a personalized, high-quality detailing experience.",
    image: {
      src: imageFive,
      alt: "Ceaser Detail team performing professional car detailing in Knoxville, TN",
      width: 1200,
      height: 800
    }
  },
  location: {
    sectionTitle: "Our Location",
    destinationAddress: "Knoxville, Tennessee, United States",
    googleMapsEmbedUrl: 
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3171.725091423573!2d-83.92073928469876!3d35.96063847911062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x885c162c5c4d890d%3A0xabcdef123456789!2sKnoxville%2C%20TN%2C%20USA!5e0!3m2!1sen!2sus!4v1710407450828!5m2!1sen!2sus",
    subCategories: [
      { 
        name: 'Apple Maps', 
        url: 'https://maps.apple.com/?address=Knoxville,%20TN,%20USA&ll=35.960639,-83.920739&q=Knoxville',
      },
      { 
        name: 'Bing Maps', 
        url: 'https://www.bing.com/maps?q=Knoxville,+TN,+USA&FORM=HDRSC6',
      },
      { 
        name: 'Google Earth', 
        url: 'https://earth.google.com/web/search/Knoxville,+TN,+USA/@35.960639,-83.920739,15a,814.57589625d,35y,0h,0t,0r',
      },
      { 
        name: 'Google Maps', 
        url: 'https://www.google.com/maps/place/Knoxville,+TN,+USA/@35.960639,-83.920739,15z',
      }
    ]
  },
  contact: {
    sectionTitle: "Ready For A Extreme Shine and Protection?",
    description: "Contact Ceaser Detail today to schedule your detailing service! Whether it's ceramic coating, paint correction, or mobile detailing, we’re here to make your vehicle shine like new.",
    socialLinks: {
      facebook: redirectLink,     
      instagram: redirectLink,     
      twitter: redirectLink,     
      linkedin: redirectLink,     
      youtube: redirectLink,     
    },
    contactNumbers: [
      "Mobile: (865) 355-1732",
    ],
    logo: bizIcon,
    emailRecipient: "caesardaonenot2@gmail.com"
  },
  footer: {
    bizSolutionsLogo: myBizIcon
  }
};


